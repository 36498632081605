import { render, staticRenderFns } from "./default-details.vue?vue&type=template&id=3ea0cc92&scoped=true&"
import script from "./default-details.vue?vue&type=script&lang=js&"
export * from "./default-details.vue?vue&type=script&lang=js&"
import style0 from "./default-details.vue?vue&type=style&index=0&id=3ea0cc92&prod&lang=scss&scoped=true&"
import style1 from "./default-details.vue?vue&type=style&index=1&id=3ea0cc92&prod&lang=css&"
import style2 from "./default-details.vue?vue&type=style&index=2&id=3ea0cc92&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea0cc92",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingBar: require('/www/togihome/buyer/components/LoadingBar.vue').default})
